function initializeMediaSlides($container) {
  var images = $container.find(".media-image")
  var currentImageIndex = 0

  function toggleImages() {
    images.eq(currentImageIndex).fadeTo(500, 1) // Fade in current image
    images.not(images.eq(currentImageIndex)).fadeTo(500, 0) // Fade out other images

    currentImageIndex = (currentImageIndex + 1) % images.length
  }

  toggleImages() // Initial call to show the first image
  setInterval(toggleImages, 5000) // Call the toggleImages function every 10 seconds
}

// Call initializeMediaSlides for each .media-slides container
$(".media-slides").each(function () {
  initializeMediaSlides($(this))
})
