



$(window).scroll(function () {
    var scroll = window.scrollY || window.pageYOffset;
  
    $('body').toggleClass('scrolled-to-top', scroll == 0 && $('main > .mega:first-child > section:first-child.block-hero').length > 0);
  });
  
  $(function () {
    $(window).scroll();
  });
  
  $(document).on('show.bs.collapse', '#navbarCollapse', function( event ) {
    $('body').addClass('menu-open');
  });
  
  $(document).on('hidden.bs.collapse', '#navbarCollapse', function( event ) {
    $('body').removeClass('menu-open');
  });