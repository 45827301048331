// Import jQuery first
window.$ = window.jQuery = require("jquery/src/jquery")

// Import Popper.js (assuming it's needed for Bootstrap)
require("../../node_modules/@popperjs/core")

// Import Bootstrap
import * as bootstrap from "bootstrap"

// Import other modules
import "../css/main.scss"
require("./modules/hero-video")
require("./modules/navbar")
require("./modules/media-beside-slider")
require("./modules/media-beside-video")
require("./modules/sliders")
require("./modules/header")
require("./modules/dropdown")
require("./modules/hero")
require("./modules/post")

require("./modules/venobox")
