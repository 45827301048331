

$(document).ready(function() {
    var videoContainers = $('.video-container'); // Get all video containers
  
    videoContainers.each(function() {
      var videoContainer = $(this);
      var video = videoContainer.find('.video')[0];
      var playIcon = videoContainer.find('.play-icon');
      var placeholder = videoContainer.find('.placeholder');
  
      if (!video) {
        return;
      }
  
      // Show placeholder and play icon on page load
      placeholder.show();
      playIcon.show();
  
      // Play icon click event
      playIcon.click(function() {
        video.style.display = 'block'; // Display the video
        video.play();
        placeholder.hide();
        playIcon.hide();
      });
  
      // Video click event
      video.addEventListener('click', function() {
        if (video.paused) {
          video.play();
          playIcon.hide();
        } else {
          video.pause();
          playIcon.show();
        }
      });
  
      // Video pause event
      video.addEventListener('pause', function() {
        playIcon.show();
      });
    });
  });