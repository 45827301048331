document.addEventListener("DOMContentLoaded", function () {
  // Initialize VenoBox for images
  var myGallery = new VenoBox({
    selector: ".gallery-item",
    numeration: true,
    infinigall: true,
    share: true,
    spinner: "bounce",
  })

  // Initialize another VenoBox instance for videos
  var myVideos = new VenoBox({
    selector: ".video-link",
    type: "iframe", // Specify the type as "iframe" for videos
  })
})
