$(document).ready(function () {
  // Select all h5 elements with the class .team_post and capitalize the text
  $(".team_post h5").each(function () {
    $(this).text(
      $(this)
        .text()
        .toLowerCase()
        .replace(/\b\w/g, function (l) {
          return l.toUpperCase()
        })
    )
  })
})
