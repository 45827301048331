$(function () {
    $('.slick').each(function () {
      var slidesToShow = $(this).data('slides-to-show') || 3;
  
      var options = {
        infinite: true,
        arrows: true,
        slidesToShow,
        slidesToScroll: 1,
        dots: $(this).data('append-dots') !== false,
        variableWidth: $(this).data('variable-width') || false,
        autoplay: $(this).data('ride') || false,
        appendArrows: $(this).data('append-arrows') && $($(this).data('append-arrows')) || $(this),
        appendDots: $(this).data('append-dots') && $($(this).data('append-dots')) || $(this).data('append-dots') !== false && $(this),
  
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: slidesToShow > 1 ? --slidesToShow : slidesToShow,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: slidesToShow > 1 ? --slidesToShow : slidesToShow,
              slidesToScroll: 1
            }
          },
        ]
      };
  
      if($(this).data('slick'))
      {
        Object.assign(options, $(this).data('slick'));
      }
  
      $(this).slick(options);
    });
  });



$('.logos').each(function () {
    var slidesToShow = $(this).data('slides-to-show') || 3 ;
  
    var options = {
      infinite: true,
      arrows: true,
      slidesToShow,
      slidesToScroll: 1,
      dots: $(this).data('append-dots') !== false,
      variableWidth: $(this).data('variable-width') || false,
      autoplay: $(this).data('ride') || false,
      appendArrows: $(this).data('append-arrows') && $($(this).data('append-arrows')) || $(this),
      appendDots: $(this).data('append-dots') && $($(this).data('append-dots')) || $(this).data('append-dots') !== false && $(this),
  
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 448,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    };
  
    if($(this).data('slick'))
    {
      Object.assign(options, $(this).data('slick'));
    }
  
    $(this).slick(options);
  });
  