jQuery(function ($) {
  /* IE 11 Fix */

  window.isIE11 = !!window.MSInputMethodContext && !!document.documentMode

  if (isIE11) {
    $(".hero > video").each(function () {
      var position = function () {
        var $hero = $(this).parents(".hero:first"),
          width_multiplier = window.innerWidth / this.videoWidth,
          height_multiplier = window.innerHeight / this.videoHeight,
          multiplier = Math.max(width_multiplier, height_multiplier),
          width = multiplier * this.videoWidth,
          height = multiplier * this.videoHeight

        $(this).css({
          width: width,
          height: height,
          position: "absolute",
          top: -(height - $hero.height()) / 2,
          left: -(width - $hero.width()) / 2,
        })
      }

      if (this.readyState <= 0) {
        this.onloadedmetadata = position.bind(this)
      } else {
        position.call(this)
      }

      $(window).resize(position.bind(this))
    })

    $(".hero > span > img").each(function () {
      var position = function () {
        var $hero = $(this).parents(".hero:first"),
          width_multiplier = window.innerWidth / this.naturalWidth,
          height_multiplier = window.innerHeight / this.naturalHeight,
          multiplier = Math.max(width_multiplier, height_multiplier),
          width = multiplier * this.naturalWidth,
          height = multiplier * this.naturalHeight

        $(this).css({
          width: width,
          height: height,
          position: "absolute",
          top: -(height - $hero.height()) / 2,
          left: -(width - $hero.width()) / 2,
        })
      }

      position.call(this)

      $(window).resize(position.bind(this))
    })
  }
})

$(window).on("load", function () {
  $(".hero > video + picture").each(function () {
    var $image = $(this)

    $(this)
      .prev()
      .each(function () {
        this.oncanplaythrough = function () {
          $image.addClass("d-none")
          $(this).removeClass("d-none")
          this.play()
        }

        this.load()
      })
  })
})
