

$(document).on('click', 'header .navbar-toggler', function (){
    $('header .collapse').toggleClass('open');
    $('header .navbar-toggler .toggler').toggleClass('active');
    $('header, body').toggleClass('menu-open');
  });
  
  $(document).on('click', 'main', function (){
    $('header .collapse').removeClass('open');
  });
  
  $(window).scroll(function () {
    var scroll = window.scrollY || window.pageYOffset;
      $('header').toggleClass('scrolled-to-top', scroll == 0);
  });